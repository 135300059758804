import * as React from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
import styled from "styled-components";

const NavContainer = styled.div`
  a {
    color: unset;
  }
`;

const NYCNav = () => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  return (
    <NavContainer>
      <Navbar
        fixed="top"
        expand="lg"
        variant={isExpanded ? "light" : "dark"}
        onToggle={() => setIsExpanded(true)}
        expanded={isExpanded}
      >
        <Navbar.Brand className="ms-3">
          <div />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse
          className={isExpanded ? "open" : "closed"}
          id="basic-navbar-nav"
        >
          <div className="navbar-collapse-header align-items-center flex-column d-flex position-relative">
            <NYCText className="ms-3 text-primary  d-block d-lg-none">
              <div>NYC</div>
              <div>2024</div>
            </NYCText>

            <div
              onClick={() => setIsExpanded(false)}
              className="collapse-close position-absolute"
              style={{ right: "0", top: "0" }}
            >
              <Navbar.Toggle
                aria-controls="basic-navbar-nav"
                className="navbar-toggler"
                id="navbar_global"
              >
                <span></span>
                <span></span>
              </Navbar.Toggle>
            </div>
          </div>
          <Nav className="m-auto">
            <Nav.Link
              as={Link}
              onClick={() => setIsExpanded(false)}
              className="p-2 ms-1 me-1"
              to="/#home"
            >
              NYC2024
            </Nav.Link>
            <Nav.Link
              as={Link}
              onClick={() => setIsExpanded(false)}
              className="p-2 ms-1 me-1"
              to="/#where-is-it"
            >
              Details
            </Nav.Link>
            <Nav.Link
              as={Link}
              onClick={() => setIsExpanded(false)}
              className="p-2 ms-1 me-1"
              to="/#faq"
            >
              FAQ
            </Nav.Link>

            <Nav.Link
              as={Link}
              onClick={() => setIsExpanded(false)}
              className="p-2 ms-1 me-1"
              to="/register"
            >
              Register
            </Nav.Link>
            {/* <Nav.Link
              as={Link}
              onClick={() => setIsExpanded(false)}
              className="p-2 ms-1 me-1"
              to="/calendar"
            >
              Schedule
            </Nav.Link> */}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </NavContainer>
  );
};

export default NYCNav;

const NYCText = styled.div`
  font-size: 2.5em;
  line-height: 0.85em;
  font-family: Questrial, sans-serif;
  font-weight: 800;
  color: rgb(237, 30, 67);
`;
