import { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <div className="mt-5" style={{ fontSize: "0.8em" }}>
        <div className="d-flex justify-content-center mx-auto align-items-center ">
          <div className="d-flex justify-content-center align-items-center">
            NYC 2024{" "}
            <span className="mx-3 mb-1" style={{ fontSize: "2em" }}>
              💛
            </span>
          </div>
          <div>&copy; Revival Fellowship</div>
        </div>
      </div>
    );
  }
}

export default Footer;
