import React, { PropsWithChildren, useEffect, useRef, useState } from "react";
import styled from "styled-components";

interface AccordionProps extends PropsWithChildren {
  tag?: string;
  title?: string;
  active?: boolean;
}
const Accordion: React.FC<AccordionProps> = ({
  children,
  tag = "li",
  title,
  active,
}) => {
  const [accordionOpen, setAccordionOpen] = useState<boolean | undefined>(
    false
  );
  const accordion = useRef<HTMLDivElement>(null);
  const Component = tag as any;

  useEffect(() => {
    setAccordionOpen(active);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accordion]);

  return (
    <Component style={{ borderBottom: "1px solid #ffffff44" }}>
      <div
        className="d-flex align-items-center w-100 text-left py-3 px-2 justify-content-between"
        style={{ outline: "none" }}
        onClick={(e) => {
          e.preventDefault();
          setAccordionOpen(!accordionOpen);
        }}
        aria-expanded={accordionOpen}
      >
        <span className="me-2">
          {" "}
          <strong className="fw-800">{title}</strong>
        </span>
        <StyledSvg
          className=" flex-shrink-0"
          viewBox="0 0 16 16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <StyledSvgRect
            y="7"
            width="16"
            height="2"
            rx="1"
            className={` transition  ${accordionOpen && "rotate-180"}`}
          />
          <StyledSvgRect
            y="7"
            width="16"
            height="2"
            rx="1"
            className={` rotate-90  ${accordionOpen && "rotate-180"}`}
          />
        </StyledSvg>
      </div>
      <StyledContents
        ref={accordion}
        style={
          accordionOpen
            ? { maxHeight: accordion.current!.scrollHeight, opacity: 1 }
            : { maxHeight: 0, opacity: 0 }
        }
      >
        <div className="mb-3 text-white">{children}</div>
      </StyledContents>
    </Component>
  );
};

export default Accordion;

const StyledSvgRect = styled.rect`
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  transition-duration: 0.2s;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform, filter, backdrop-filter, -webkit-transform,
    -webkit-filter, -webkit-backdrop-filter;
  transform-origin: center;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;

  &.rotate-90 {
    --tw-rotate: 90deg;
  }

  &.rotate-180 {
    --tw-rotate: 180deg;
  }

  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
`;

const StyledSvg = styled.svg`
  width: 1rem;
  height: 1rem;
  display: block;
  vertical-align: middle;
  fill: currentColor;
`;

const StyledContents = styled.div`
  transition-property: all;
  overflow: hidden;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
`;
