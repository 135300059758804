import Button from "react-bootstrap/esm/Button";
import { Link } from "react-router-dom";
import styled from "styled-components";
import nyc from "../images/nyc.jpg";

import { useEffect, useState } from "react";

const useCountdown = (targetDate) => {
  const countDownDate = new Date(targetDate).getTime();

  const [countDown, setCountDown] = useState(
    countDownDate - new Date().getTime()
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  return getReturnValues(countDown);
};

const getReturnValues = (countDown) => {
  // calculate time left
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return [days, hours, minutes, seconds];
};

const Header = () => {
  const [days, hours, minutes, seconds] = useCountdown(
    new Date("2024-04-07T13:59:59.999Z")
  );
  return (
    <StyledHeader id="home" className="d-flex pt-5">
      <div className="py-5 mt-5 d-flex flex-column align-items-center">
        <IMG className="mx-auto  mb-4" src={nyc} />

        {days + hours + minutes + seconds <= 0 ? (
          <div className="expired-notice">
            <span>Registration has now closed</span>
          </div>
        ) : (
          <>
            <div className="d-flex align-items-center flex-column gap-3">
              <div>Registration closes</div>
              <div className="countdown-timer">
                <ShowCounter
                  days={days}
                  hours={hours}
                  minutes={minutes}
                  seconds={seconds}
                />
              </div>
            </div>
            <div className="mt-5">
              <Link className="mx-auto mt-5" to="/register">
                <Button className="btn-cta">
                  <span>Register</span>
                </Button>
              </Link>
            </div>
          </>
        )}
      </div>
    </StyledHeader>
  );
};

const StyledHeader = styled.div`
  line-height: 1em;
`;
const IMG = styled.img`
  max-width: min(450px, 80%);
  padding: 12px;
`;
const TXT = styled.div`
  height: 1.6ch;
`;

export default Header;

const ShowCounter = ({ days, hours, minutes, seconds }) => {
  return (
    <div className="show-counter">
      <DateTimeDisplay value={days} type={"days"} isDanger={days <= 3} />
      <span>, </span>
      <DateTimeDisplay value={hours} type={"hours"} isDanger={false} />
      <span>, </span>
      <DateTimeDisplay value={minutes} type={"mins"} isDanger={false} />
      <span>, </span>
      <DateTimeDisplay value={seconds} type={"seconds"} isDanger={false} />
    </div>
  );
};

const DateTimeDisplay = ({ value, type, isDanger }) => {
  return (
    <span className={isDanger ? "countdown danger" : "countdown"}>
      <span>{value}</span> <span>{type}</span>
    </span>
  );
};
