import AOS from "aos";
import * as React from "react";
import { withRouter } from "react-router-dom";
import { Sticky } from "sticky-ts";
import "../App.scss";
import Faq from "../components/Faq";
import Footer from "../components/Footer";
import Header from "../components/Header";
import WhereIsIt from "../components/WhereIsIt";
import beach from "../images/beach.png";

const Home = withRouter(({ location }) => {
  React.useEffect(() => {
    AOS.init({
      once: true,
      // disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const sticky = new Sticky("[data-sticky]");
  });

  React.useEffect(() => {
    if (!location.hash) {
      return;
    }
    let element = document.getElementById(location.hash.slice(1));
    if (element) {
      console.log(element);
      window.scrollTo(0, element.offsetTop);
    }
  }, [location.hash]);
  return (
    <div
      className="position-relative"
      style={{ overflowX: "hidden", overflowY: "clip" }}
    >
      <Header />

      {/* <About /> */}
      <WhereIsIt />
      {/* <WhatToBring /> */}

      <Faq className="pb-5">
        <div className="d-flex align-items-center flex-column gap-3 mb-4">
          <div>Looking for a swim? Only 500m away.</div>
          <img style={{ width: "min(50%, 40em)" }} src={beach}></img>
        </div>
        <Footer />
      </Faq>
    </div>
  );
});

export default Home;
