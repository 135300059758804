import Accordion from "../util/Accordian";
import WhatToBring from "./WhatToBring";

const Faq = ({ children, ...props }) => {
  return (
    <section {...props} id="faq" className="full-height-section">
      <div
        style={{ width: "max(300px, min(80vw, 600px))" }}
        className="mx-auto px-2 px-sm-3"
      >
        <div className="py-4 py-md-5">
          {/* Faqs */}
          <ul className="list-unstyled mx-auto ps-4">
            <Accordion title="What is NYC?">
              National Youngies Camp (NYC) is a youth Christian based event
              supported by the Revival Fellowship Australia and is coordinated
              by volunteer members, leaders and Pastors of the Revival
              Fellowship from across Australia.
            </Accordion>
            <Accordion title="Do I need to register?">
              Yes, registration is essential.
            </Accordion>
            <Accordion title="When is NYC24?">
              NYC24 starts on Fri 3rd May – Arrive from 6pm and finishes Mon 6th
              May – Leave at 2pm.
            </Accordion>
            <Accordion title="Where is it and how do I get there?">
              The camp is located at Collaroy Center –{" "}
              <a href="https://goo.gl/maps/Z3u5P69obgSbuQgG9">
                22 Homestead Ave, Collaroy NSW 2097
              </a>
              . Contact our Travel Coordinator early to arrange transfer from
              either the airport or local train stations.
            </Accordion>
            <Accordion title="How much does it cost?">
              <b className="text-decoration-underline">Standard</b> $270 bed
              only / $285 incl linen p/p (16yrs+)
              <br />
              <br />
              Shared dormitory bunk accommodation & bathroom. You cannot select
              this option if you have an infant with you.
              <br />
              <br />
              <b className="text-decoration-underline">Deluxe Room</b> $330 incl
              linen p/p
              <br />
              <br />
              Private dormitory accommodation with bathroom. Three single beds.
              BYO travel cot.
              <br />
              <br />
              <b className="text-decoration-underline">
                Couples Hotel Room
              </b>{" "}
              $350 incl linen p/p
              <br />
              <br />
              Private Hotel accommodation with bathroom. Two king single beds +
              1 fold-out single sofa. BYO travel cot.
              <br />
              <br />
              Infant/Toddler $60 p/p
              <br />
              <br />
              12 months -3yrs (Infants under 12months are free)
              <br />
              <br />
              <b className="text-decoration-underline">Day Visitors</b> $39
              (lunch OR dinner) or $61.50 (inc lunch AND Dinner).
              <br />
              Lunch includes morning tea, lunch, afternoon tea. Dinner includes
              afternoon tea, dinner, supper.
              <br />
              <br />
              Please note: All fees have been subsidised by SEQ Ministry and
              Vision 20.
            </Accordion>
            <Accordion title="Can I bring my kids?">
              No. To get the most out of camp we advise that you arrange for
              your child to be looked after for the weekend. However, newborns
              or infants (under 3 years) are welcome. If you have any questions
              please contact our team directly.
            </Accordion>
            <Accordion title="Can I get a refund?">
              Unfortunately we cannot provide a refund once you've booked and
              paid. However, if for some reason you are unable to attend due to
              a family crises or health related issue we will do our best to
              assist in either a part or full refund.
            </Accordion>
            <Accordion title="How old do I need to be?">
              NYC caters for people aged 16 years and up. The weekend events and
              ministry is best suited for this age and we cannot accomodate
              anyone under 16 years.
            </Accordion>
            <Accordion title="How many nights does it go for?">
              National Youngies Camp starts on Friday evening and finishes on
              Monday early afternoon after lunch and includes 3 nights of
              accommodation.
            </Accordion>
            <Accordion title="Do I need to bring food?">
              No, your camp fees include all main meals and snacks. If you have
              a food allergy be sure to let us know within your registration.
            </Accordion>
            <Accordion title="Do I need to bring bedding?">
              Linen Packs are available for an extra charge of just $15.
              <br /> If you do not purchase a linen pack you will need to bring
              your own single bedding including a single sheet set (top sheet
              and fitted sheet), pillow and pillow slip & towel.
            </Accordion>
            <Accordion title="I’ve got more questions!">
              No worries – don't hesitate to get in touch with our team directly{" "}
              <a href="mailto:nationalyoungiescampteam@gmail.com">
                nationalyoungiescampteam@gmail.com
              </a>
            </Accordion>
            <Accordion title="What else do I need to bring?">
              For experienced campers you'll know what to bring but for those
              who are coming to National Youngies Camp for the first time and
              for those who need reminding here is a simple list to start with:
              <WhatToBring />
            </Accordion>
            <Accordion title="Can you help with Financial Support?">
              We don't want anyone to miss out! It's best that you contact your
              local church Pastor if require financial assistance. If you are
              unable to do this and need help please contact us via email{" "}
              <a href="mailto:nationalyoungiescampteam@gmail.com">
                nationalyoungiescampteam@gmail.com
              </a>
            </Accordion>
          </ul>
        </div>
      </div>
      {children}
    </section>
  );
};

export default Faq;
