import { Component } from "react";

class WhatToBring extends Component {
  render() {
    return (
      <div style={{ maxWidth: "300px", textAlign: "left", margin: "0 auto" }}>
        <ul>
          <li>Bedding - Single Beds (sheets, blanket, pillow)</li>
          <li>Towels (shower & beach)</li>
          <li>Appropriate clothing for weather</li>
          <li>Active wear</li>
          <li>Bible & Notepad</li>
          <li>Toiletries inc. Toothbrush, body wash etc.</li>
          <li>Swimwear & beach towel</li>
          <li>Sunscreen</li>
          <li>Hat</li>
        </ul>
      </div>
    );
  }
}

export default WhatToBring;
