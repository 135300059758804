import * as React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import styled from "styled-components";
import "./App.scss";
import BeginRegistration from "./components/BeginRegistration";
import BeginRegistrationDayTrip from "./components/BeginRegistrationDayTrip";
import BookingSuccess from "./components/BookingSuccess";
import Calendar from "./components/Calendar";
import Footer from "./components/Footer";
import Nav from "./components/Nav";
import Home from "./pages/Home";

const App = () => {
  return (
    <BrowserRouter>
      <Nav />
      <StyledApp className="App">
        <Switch>
          <Route path="/register">
            <BeginRegistration />
          </Route>
          <Route path="/register_day_trip">
            <BeginRegistrationDayTrip />
          </Route>
          <Route path="/calendar">
            <Calendar />
            <Footer />
          </Route>

          <Route path="/booking-success">
            <BookingSuccess />
            <Footer />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </StyledApp>
    </BrowserRouter>
  );
};

export const StyledApp = styled.div`
  margin: 0;
  padding: 0;
`;

export default App;
