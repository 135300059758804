import { Component } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";

class WhereIsIt extends Component {
  render() {
    return (
      <section id="where-is-it" className="full-height-section d-flex">
        <div className="text-center mx-auto">
          <div className="fs-4">
            Join us for NYC 2024 on the beautiful Northern Beaches of Sydney.
            <br />
            Open to all ages 16+
          </div>
          <div className="mt-4">
            <strong className="fw-800">Fri 3th May</strong> – Arrive from 6pm
          </div>
          <div className="mb-4">
            <strong className="fw-800">Mon 6th May</strong> – Leave at 2pm
          </div>
          <div>
            <strong className="fw-800">Collaroy Centre – </strong>
          </div>
          <p>
            <a
              style={{ color: "rgb(0,173,84)" }}
              href="https://goo.gl/maps/Z3u5P69obgSbuQgG9"
            >
              22 Homestead Ave, Collaroy NSW 2097
            </a>
          </p>
        </div>

        <div className="rego-due mt-5">
          <span>Registration and payment is essential before April 7th</span>
        </div>
        <div className="mt-5 d-flex d-lg-none">
          <Link className="mx-auto mt-5" to="/register">
            <Button className="btn-cta ">
              <span>Register</span>
            </Button>
          </Link>
        </div>
      </section>
    );
  }
}

const Title = styled.div`
  font-size: 2em;
  line-height: 1.1em;
  font-family: Questrial, sans-serif;
  font-weight: 900;
  font-style: italic;
`;

export default WhereIsIt;
