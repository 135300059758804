import React, { Component } from "react";
import Button from "react-bootstrap/Button";

class BookingSuccess extends Component {
  render() {
    return (
      <section id="booking-success">
        <h1>You're all booked in.</h1>
        <br />
        <br />
        <p>What's Next?</p>
        <a href="/#faq" className="">
          <Button>Show me what to bring</Button>
        </a>
      </section>
    );
  }
}

export default BookingSuccess;
